/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <div class="col-md-12 mb-3">
              <label for="">{{ cvSelecteCountryStrategy }}</label>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="ms_country_strategy"
                :options="country_strategy_list"
                name="flavour-1"
                ></b-form-checkbox-group>
              </div>
            <button type="button" class="btn btn-primary" @click="spcm_Edit()">Edit Strategy</button>&nbsp;&nbsp;
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { spcmMilestones } from "../../../FackApi/api/Spcmmilestones.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { Spcms } from "../../../FackApi/api/Spcm.js"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "spcmCountryStrategyAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propstrategyObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      cvCardTitle: "Add Country Strategy ",
      cvCardSubHeader: "Add Country Strategy ",
      cvSubmitBtn: "Add",
      cvSelecteCountryStrategy: "Selected Strategy",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Country Strategy ",
      country_strategy_list: [],
      ms_country_strategy: []
    }
  },
  mounted () {
    this.spcmView()
    this.spcm_CountryStrategyList()
    socialvue.index()
  },
  methods: {
    /**
     * spcm_Edit
    */
    async spcm_Edit () {
      try {
        if (this.propstrategyObj) {
          const arr = this.ms_country_strategy
          const msCountry = arr.join(", ")
          this.propstrategyObj.ms_country_strategy = msCountry
        }
        let spcmAddResp = await Spcms.spcmEdit(this, this.propstrategyObj)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmStretegyModal", this.propstrategyObj)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_Edit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * spcm_CountryStrategyList
    */
    async spcm_CountryStrategyList () {
      try {
        let spcmCountryStrategyListResp = await spcmMilestones.spcm_CountryStrategyList(this, this.whereFilter)
        if (spcmCountryStrategyListResp.resp_status) {
          this.spcmCountryStrategyListResp = spcmCountryStrategyListResp.resp_data.data
          this.country_strategy_list = this.spcmCountryStrategyListResp.map(e => e.ms_country_strategy)
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = spcmCountryStrategyListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_CountryStrategyObjList() and Exception:", err)
      }
    },
    /**
     * spcmView
     */
    async spcmView () {
      try {
        if (this.propOpenedInModal) {
          this.country_list = this.propstrategyObj.ms_country_strategy
          this.ms_country_strategy = this.country_list.split(",").map(item => item.replace(/[,\s]/g, ""))
        }
        else {
          let spcmId = this.$route.params.spcm_id
          let spcmViewResp = await Spcms.spcmView(this, spcmId)

          if (spcmViewResp && spcmViewResp.resp_status) {
            this.ms_country_strategy = spcmViewResp.resp_data.data.ms_country_strategy
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmView() and Exception:", err.message)
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
