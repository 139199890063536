<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <!-- <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template> -->
          <template v-slot:body>
            <form action="#" v-if="vmChangeCounsellorFormData && Object.keys(vmChangeCounsellorFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationcounsellor_id">{{ cvCounsellorLabel }}</label>
                  <input readonly v-model="vmChangeCounsellorFormData.counsellor_name" type="text" class="form-control" required/>
                </div>

               <div class="col-md-12 mb-3">
                  <label for="validationcounsellor_id">New Lead Strategist</label>
                  <select class="form-control" v-model="vmChangeCounsellorFormData.new_counsellor_id">
                    <option :value="counsellor.counsellor_user_id" v-for="(counsellor, index) of counsellorObjList" :key="index">{{counsellor.user_name}}</option>
                  </select>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationslot_duration">{{ cvStudentNameLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                  <input readonly v-model="vmChangeCounsellorFormData.stu_name" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationslot_duration">{{ cvStudentEmailLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                  <input readonly v-model="vmChangeCounsellorFormData.stu_email" type="text" class="form-control" required />
                </div>

                <p class="ml-1">
                  <button type="button" class="btn btn-primary" @click="changeCounsellor()">Modify</button>&nbsp;&nbsp;
                </p>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { Spcms } from "../../../FackApi/api/Spcm"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"

export default {
  name: "ChangeCounsellor",
  data () {
    return {
      cvCounsellorLabel: "Current Lead Strategist Name",
      cvStudentNameLabel: "Student Name",
      cvStudentEmailLabel: "Student Email",
      cvModules: "Modify",
      counsellorObjList: [],
      toastVariant: "default",
      toastTitle: "Invite User",
      toastMsg: null,
      showToast: false,
      vmChangeCounsellorFormData: {}
    }
  },
  mounted () {
    this.vmChangeCounsellorFormData = { ...this.propUserObj }
    this.getCounselorFilterList()
  },
  props: {
    propUserObj: {
      type: Object,
      default: null
    },
    propPdtCode: {
      type: String,
      default: "gpaths"
    }
  },
  methods: {
    /**
     * changeCounsellor
     */
    async changeCounsellor () {
      // console.log(this.vmChangeCounsellorFormData)
      try {
        this.vmChangeCounsellorFormData.old_counsellor_id = this.propUserObj.counsellor_id
        this.vmChangeCounsellorFormData.modules = this.propPdtCode
        const changeCounsellorResp = await Spcms.spcmChangeCounsellorEdit(this, this.vmChangeCounsellorFormData)
        if (changeCounsellorResp.resp_status) {
          this.toastMsg = changeCounsellorResp.resp_msg
          this.toastVariant = "success"
          this.showToast = true
          this.$emit("emitCloseChangeCounselllor")
        }
        else {
          this.toastMsg = changeCounsellorResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Error in changeCounsellor Err: ", err)
      }
    },
    /**
     * getCounselorFilterList
     */
    async getCounselorFilterList () {
      const counselorsListResp = await SpcmCounsellors.spcmCounsellorList()
      if (counselorsListResp.resp_status) {
        this.counsellorObjList = counselorsListResp.resp_data.data
      }
    }
  }
}
</script>

<style>

</style>
